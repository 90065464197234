
/* .checkout_page .res-down-product{
    height: 50px;
    width: 50px;
    overflow: hidden;
} */
.checkout_page .summary-inner{
    height: auto;
    max-height: 340px;
    overflow-y: scroll;
    margin-bottom: 14px;
}
.checkout_page ::-webkit-scrollbar {
    width: 4px;
    margin: 6px;
  }  
.checkout_page ::-webkit-scrollbar-track {
    background: #D60012; 
  }


.checkout_page .dark_black_div{
    background-color: #0D0D0D;
    color: white;
}
.checkout_page .red_div{
    background-color: #D60012;
    height: 280px;
}
.checkout_page .mb-280{
    margin-bottom: 280px;
}
.checkout_page .forg-color{
    color: #B1B1B1;
}
.checkout_page .form_box{
    /* max-width: 500px ; */
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 2.5rem;
    position: relative;
    top: -69%; 
}
.checkout_page .d-flex{
    display: flex;
}
.checkout_page .by_creating_acc{
    display: flex;
    justify-content: center;
}
.checkout_page .font-roboto{
    font-family: 'Roboto', sans-serif!important;
}
.checkout_page .font-roboto-medium{
    font-family: Roboto-Medium;
}
.checkout_page .font-roboto-regular{
    font-family: Roboto-Regular;
}
.checkout_page .font-roboto-bold{
    font-family: Roboto-Bold;
}
.checkout_page .font-roboto-medium{
    font-family: Roboto-Medium;
}
.checkout_page .font-mon-medium{
     font-family: Montserrat-Medium;
}
.checkout_page .font-mon-semibold{
    font-family: Montserrat-SemiBold;
}
.checkout_page .f-22{
    font-size: 22px;
}
.checkout_page .font-pop{
    font-family: 'Poppins', sans-serif !important;
}
.checkout_page .font-mon{
    font-family: 'Montserrat', sans-serif;
}
.checkout_page .form-signup{
    /* max-width: 500px; */
    margin: auto;
    text-align: left;   
}
.checkout_page .signup-fld , .checkout_page .signup-fld:hover ,.checkout_page .signup-fld:active , .checkout_page .signup-fld:focus{
    background: transparent;
    color: white;  
    background: white;
    color: black;
    padding: 12px;
    border-radius: 0px;
}
.checkout_page .saved_payment_box{
    padding: 1.5rem;
    border: 1px solid lightgrey;
    border-radius: 10px;    
}
.checkout_page .saved_payment_header{
    text-align: center;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
}
.checkout_page .set_as_default{
    background: #d60012;
    border-radius: 60px;
    padding: 20px 16px;
}
.checkout_page .icon_tick{
    background: transparent;
    color: #d60012;
    cursor: pointer;
}
.checkout_page .brdr-top-light{
    border-top: 1px solid white;
    width: 100%;
}
.checkout_page .gry-clr{
    color: #949494;
}
.checkout_page .f-31{
    font-size: 31px;
}
.checkout_page .f-14{
    font-size: 14px;
    font-weight: 400;
}
.checkout_page .f-21{
    font-size: 21px;
}
.checkout_page .clr-red{
    color:#D60012;
}
.checkout_page .mt-4rem{
    margin-top: 4rem!important;
}
.checkout_page .text-right{
    text-align: right;
}
.checkout_page .txt-area{
    resize: none;
}
.checkout_page .view_more{
    background-color: #D60012;
    color: white;
    padding: 7px 45px;
    border-radius: 0px;
}
.checkout_page .f-15{
    font-size: 15px;
}
.checkout_page .f-16{
    font-size: 16px;
}
.checkout_page .f-13{
font-size: 13px;
}
.checkout_page .f-18{
    font-size: 18px;
}
.checkout_page .product_detail_image{
    height: 60px;
    width: 60px;
}
.checkout_page .align-center{
    align-items: center;
}
.checkout_page .f-20{
    font-size: 20px;
}
.checkout_page .border-red{
    width: auto;
    height: 44px;
    border: 1px solid transparent;
    float: left;
}
.checkout_page .border-red:hover{
    border-color: white!important;
}
.checkout_page .border-red:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
}
.checkout_page .btn:hover {
    color: white;
}
.checkout_page .med-ps-5{
    padding-left:3rem ;
}
.checkout_page .promo_field{
    background-color: transparent;
    color: white;
    border-radius: 0px;
}
.checkout_page .order-box{
    border: 1px solid white;
}
.checkout_page .d-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 8px;
}
.checkout_page .radio_add_box{
    background-color: #fff;
}
.checkout_page .brdr-right-grey_radio{
    border-right: 1px solid #707070;
}
.checkout_page .flex1{
    flex: 1;
}
.checkout_page .width_full{
    width: 100%;
}
.checkout_page .default_btn{
    outline: none;
    padding: 7px 25px;
    border: none;
}
.checkout_page .pink-color{
    color: #d81b60;
}
.checkout_page .brdr-btm-light{
    border-bottom: 1px solid #fff;
}
.checkout_page .bt-hover-color:hover{
    background-color: white;
    color: black;
}
.checkout_page select.form-control { -webkit-appearance: menulist; }

select.form-control:not([size]):not([multiple]) {
    height: auto!important;
}
.checkout_page .css-1ji7omv-MuiBackdrop-root{
    background-color: rgb(0 0 0 / 92%)!important;
}
.checkout_page .thankYou_div{
    border: 2px solid white;
    border-radius: 20px;
    text-align: center;
}
.checkout_page .font-pop-med{
    font-family: Poppins-Medium;
}

/* media queries */
@media only screen and (max-width: 990px){.checkout_page 
    .checkout_page .media-mt-4{
        margin-top:1.5rem;
    }
    .checkout_page .form_box {
        width: auto;
        top: -56%;
    }
    .checkout_page .red_div {
        height: 350px;
    }
    .checkout_page .med-ps-5{
        padding-left:1rem ;
    }
    .checkout_page .med-dflex{
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }    
    .checkout_page .d-med-login{
        flex-direction: column;
    }
}

@media only screen and (max-width: 1150px){
    .checkout_page .pe-res-3{
        padding-right: 1rem!important;
    }
    .checkout_page .p-price{
        flex-direction: column!important;
    }
    .checkout_page .align-center {
        align-items: flex-start;
    }
    .checkout_page .res-down-product{
        flex-direction: column;
        align-items: baseline;
    }
    .checkout_page .res-set-lpt{
        margin-left: 0px!important;
        margin-top: 1rem;
    }
}
