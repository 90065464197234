html {
    overflow-x: hidden;
}
body {
    background-color: #0D0D0D;
}

.image-width {
    width: 100%;
}
.arrow-icon {
    color: #ffff;
}

.page-item.active .page-link {
    background-color: #d60012 !important;
    border-color: #d60012 !important;
}

.btn-primary {
    border-radius: unset !important;
    color: #0D0D0D !important;
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;
    padding: 10px 30px !important;
    padding: 7px 23px !important;
    margin-top: 29px;
    font-size: 14px;
    font-family: Roboto-Regular;
}

button.btn.btn-primary {
    position: relative;
    z-index:2;
}

button.btn.btn-primary:before {
    content: '';
    background: transparent;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.6s;
    z-index:-1;
}

button.btn.btn-primary:hover:before {
    left: 12px;
    top: 12px;
}
.loader-container{
    text-align: center;
    padding: 145px 10px;
}
.cart-num{
    background-color: #d60012;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    height: 18px;
    margin-bottom: -7px;
    text-align: center;
    width: 18px;
}

.stripePay-Btn {
    background-color: #D60012;
    color: #ffff;
    padding: 10px 10px;
    width: 100%;
}
.StripeElement{
    background-color: #fff;
    padding: 16px 12px;
}
.flex-style {
    display: flex;
    justify-content: center;
    align-items: center;
}
.address-space {
    display: flex;
}
.address-space .details {
    margin-left: 6px !important;
}
.address-space p{
  font-size: 14px !important;
}

/* Footer  */
.footer{
    background-color: #D60012;
}

.footer .footer-logo{
    height: 80px;
    width: 80px;
    overflow: hidden;
}
.footer .footer-logo img{
    height: 80px;
    width: 80px;
}

.footer-copyright{
    background-color: black;
    color: aliceblue;
}
.footer .first-col {
    color: #FFFFFF;
    font-size: 13px;
    font-family: Roboto-Regular;
    margin-top: 26px;
}
.copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.footer .inner-container{
    padding: 60px 60px;
}

.footer .widgets2 ul li {
   list-style: none;
   margin-top: 6px;
}
.footer .widgets2 ul li a {
  color: #FFFFFF;
  text-decoration: none;
 }
 .footer .widgets2 ul li a:hover {
    color: #FFFFFF;
    text-decoration: underline;
   }

.footer .blog-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.footer .footer-profile {
    height: 65px;
    width: 65px;
    border-radius: 8px;
    /* background-color: aliceblue; */
    margin-right: 18px;
    overflow: hidden;
}

.footer .blog-container .title{
    color: #FFFFFF;
    font-size: 11px;
    font-family: Roboto-Bold;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
}
.footer .blog-container .date{
    color: #FFFFFF;
    font-size: 9px;
    font-family: Roboto-Bold;
    margin: 0px;
}

.footer .widgets2 .mainText{
    text-align: initial;
    font-size: 18px;
    font-family: Poppins-SemiBold;
    text-decoration: underline;
    color: #ffff;
    margin-left: 33px;
}

.footer .widgets2 ul {
    text-align: initial;
    font-size: 15px;
    font-family: Roboto-Regular;
    color: #ffff;
}

.footer .blog {
    margin-left: 50px;
}

.footer .border-right {
    border-right: 1px solid #2525253d !important;
}
/* Footer end  */

/* new arrival */
.newArival-container{   
    height: auto;
    padding: 0px 60px 68px 60px;
    /* padding: 68px 60px; */
}
.newArival-container .title{
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    font-family: Roboto-bold;
}
.newArival-container .title span{
    text-align: center;
    font-weight: bold;
    color: #EC0014;
    font-family: Roboto-bold;
}
.newArival-container .para {
    text-align: center;
    font-size: 15px;
    color: #FFFFFF;
}
.newArival-container .seeAll{
    font-family: Poppins-SemiBold;
    color: #FFFFFF;
    font-size: 18px;
    margin-top: 54px;
}

.newArival-container .seeAll:hover{
   text-decoration: underline;
}

/* new arrival end  */

.AddsBanner-container{
    /* height: 55vh; */
    width: 100%;
    overflow: hidden;
}
.AddsBanner-container .inner-container {
    position: absolute;
    padding: 80px 440px 80px 130px;
}

.AddsBanner-container .inner-container h4 {
    font-family: Roboto-Medium;
    color: #FFFFFF;
    font-size: 44px;
}

.AddsBanner-container .button-container{
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 18px;
}
/* News Letter  */
.NewsLetter-container{
    background-color: #1C1C1C;
    height: 300px;
    width: 100%;
    padding: 45px 60px;
}
.NewsLetter-container .logo{
  text-align: center;
  height: 80px;
  /* width: 80px; */
  overflow: hidden;
}
.NewsLetter-container .logo img{
    height: 80px;
    width: 80px;
  }
.NewsLetter-container input ::placeholder {
    color: #ffff;
    opacity: 1; 

}
.NewsLetter-container p{
    text-align: center;
    font-size: 17px;
    color: #FFFFFF;
    font-family:Roboto-Regular;
}

.NewsLetter-container .input-container{
    text-align: center;
}

.NewsLetter-container .input-container input {
    width: 36%;
    height: 40px;
    background: #151515;
    border-bottom: unset;
    border: unset;
    padding: 10px 34px;
    color: #ffff;
    /* z-index: 999999999999; */
    outline: none;
}
.NewsLetter-container .input-container .footer-icon {
    position: relative;
    left: -35px;
    cursor: pointer;
}
/* News Letter  end*/

/* Avail  */
.Avail{
    padding: 55px 0px;
    /* height: 94vh; */
    width: 100%;
}

.Avail .avail-inner-container{
    padding: 68px 50px;
}

.Avail .img-container {
    width: 100%;
    height: 540px;
    overflow: hidden;
}
.Avail .detail-card{
    background-color: #D60012;
    height: 100%;
    width: 100%;
    padding: 44px 50px;
    /* padding: 75px 50px; */
}
.Avail .col-md-7 {
    padding: 22px 0px;
}
.Avail .col-md-5 {
    padding: 0;
}

.Avail h2 {
    font-size: 38px;
    font-family: Roboto-Bold;
    color: #FFFFFF;
    margin-bottom: 28px;
}
.Avail p{
    font-family: 'Roboto-Regular';
    font-size: 17px;
    color: #FFFFFF;
    width: 65%;
}

.Avail .learnMore-btn{
    margin-right: 20px;
}
/* Avail End  */


/* popular Items section */
.popular-section{
    padding: 100px 0px;
}
.popular-section .title {
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    font-family: Roboto-bold;
}
.popular-section .title span{
    text-align: center;
    font-weight: bold;
    color: #EC0014;
    font-family: Roboto-bold;
}
.popular-section .para{
    text-align: center;
    font-size: 17px;
    color: #FFFFFF;
    margin-top: 35px;
}

.popular-section .popular-header {
    padding: 10px 162px 60px 162px;
}

.popular-section .pop-card{
    padding: 20px
}

.popular-section .pop-card-img{
    /* height: 436px; */
    width: 100%;
    overflow: hidden;
    position: relative;
}

.popular-section .pop-card-img img {
    height: 430px;
    width: 100%;
    overflow: hidden;
    position: relative;
    object-fit: cover;
}

.popular-section .pop-card .desc {
    color: #FFFFFF;
    text-align: center;
    font-family: Roboto-Regular;
    font-size: 17px;
    margin-top: 20px;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    z-index: 9;
}
.popular-section .button-container{
    text-align: center;
    margin-top: 38px;
}

/* Hover  */
.pop-card .pop-cardHover {
    position: absolute;
    top: 0;
    bottom: 36px;
    right: 0;
    /* font-size: 0; */
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.6s;
}
.pop-card .pop-cardHover:before {
    content: '';
    background: transparent;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.6s;
    /* z-index:-0; */
    z-index: -1;
}

.pop-card .pop-cardHover:hover:before {
    left: 12px;
    top: 12px;
}
.pop-card .pop-cardHover:hover {
    background-color: #ec0014ab;
        position: absolute;
        top: 19px;
        bottom: 97px;
        right: 33px;
        /* font-size: 0; */
        left: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 100;
        color: #fff;
}

.icon-hover{
    padding: 14px;
    background-color: #ffff;
    color: #d60012;
    margin: 4px;
}

/* popular Items section end */ 

/* Product Card  */
.product-card {
    margin: 20px 0px;
}
.product-card .card{
    background-color: unset !important;
    width: 100%;
    /* height: 220px; */
}
.product-card .card .card-image{
  /* height: 310px; */
  overflow: hidden;
}

.product-card .card .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 0px 0px;
}

.product-card .card .title{
    font-size: 21px;
    color: #FFFFFF;
    font-family: Poppins-SemiBold;
}

.product-card .card .price {
    font-size: 18px;
    color: #FFFFFF;
    font-family: Poppins-SemiBold;
}

.product-card .card .desc {
    font-size: 16px;
    font-family: 'Poppins-Regular';
    color: #707070;
}
/* Product Card End  */

@media (max-width: 1150px) {
    .pop-card .pop-cardHover:hover {
        top: 19px;
        bottom: 106px;
        right: 33px;
        left: 34px;
    }
    .popular-section .popular-header {
        padding: 10px 126px 55px 126px;
    }
    .popular-section .para {
        font-size: 15px;
    }
    .product-card .card .card-image {
        /* height: 218px; */
    
    }
    .product-card .card .title {
        font-size: 15px;
    }
    .AddsBanner-container .inner-container h4 {
        font-size: 30px;
    }
    .AddsBanner-container .button-container {
        width: 46%;
    }
    .popular-section .pop-card-img {
        /* height: 308px; */
    }
    .Avail .col-md-7 {
        padding: 28px 0px;
    }
    .Avail .detail-card {
        padding: 45px 50px;
    }
    .Avail .avail-inner-container {
        padding: 68px 16px;
    }
    .Avail {
        padding: 38px 0px;
    }
    .Avail h2 {
        font-size: 35px;
    }
}

@media (max-width: 1024px) {
    .AddsBanner-container .button-container {
        width: 55%;
    }
    .pop-card .pop-cardHover:hover {
        top: 19px;
        bottom: 106px;
        right: 33px;
        left: 34px;
    }
}
@media (max-width: 768px) {
    .newArival-container {
        height: auto;
        padding: 35px 60px;
    }
    .AddsBanner-container .inner-container h4 {
        font-size: 16px;
    }
    .AddsBanner-container .inner-container {
        position: absolute;
        padding: 33px 300px 10px 144px;
    }
    .AddsBanner-container .button-container {
        width: 75%;
    }
    .popular-section .popular-header {
        padding: 2px 80px 40px 80px;
    }
    .popular-section .para {
        font-size: 13px;
    }
    .popular-section .pop-card .desc {
        font-size: 14px;
        margin-top: 10px;
    }
    .popular-section .button-container {
        margin-top: 24px;
    }
    .btn-primary {
        padding: 8px 24px !important;
        font-size: 13px;
    }
    .Avail {
        padding: 10px 0px;
    }
    .Avail .detail-card {
            padding: 90px 26px;
    
    }
    .Avail p {
        font-size: 16px;
        width: 85%;
    }
    .Avail .avail-inner-container {
        padding: 45px 10px;
    }
    .footer .border-right .footer-logo img{
        width: 140px !important; 
    }
    .NewsLetter-container .input-container input {
        width: 60%;
    }
    .pop-card .pop-cardHover:hover {
        top: 19px;
        bottom: 88px;
        right: 33px;
        left: 34px;
    }
}

@media (max-width: 425px) {
    .AddsBanner-container .inner-container {
        position: absolute;
        padding: 2px 122px 2px 45px;
    }
    .AddsBanner-container .inner-container h4 {
        font-size: 11px;
    }
    .AddsBanner-container .inner-container h4 {
        font-size: 11px;
    }
    .btn-primary {
        padding: 4px 10px !important;
        font-size: 11px;
    }
    .AddsBanner-container .button-container {
        width: 80%;
        margin-top: 12px;
    }
    .AddsBanner-container .inner-container {
        position: absolute;
        padding: 14px 155px 2px 34px;
    }
    .popular-section {
        padding: 65px 0px;
    }
    .popular-section .popular-header {
        padding: 2px 40px 34px 40px;
    }
    .footer .border-right {
        border-right: unset !important;
    }
    .footer .blog {
        margin-left: 0px;
    }
    .footer .widgets2 .mainText {
        text-align:initial;
    }
    .footer .widgets2 ul {
        text-align:initial;
    }
    .NewsLetter-container {
        padding: 44px 22px;
    }
    .NewsLetter-container .input-container input {
        width: 90%;
    }
    .top-subSlider .title {
        font-size: 10px;
    }
    .newArival-container {
        padding: 5px 22px;
    }
    .newArival-container .seeAll {
        margin-top: 55px;
    }
    .copyright {
        font-size: 12px;
    }
    .Avail .col-md-7 {
        padding: 0px 0px;
    }
    .Avail .detail-card {
        padding: 65px 26px;
    }
    .pop-card .pop-cardHover:hover {
        top: 19px;
        bottom: 66px;
        right: 33px;
        left: 34px;
    }
}

@media (max-width: 375px) {
    .newArival-container {
        height: auto;
        padding: 20px 10px;
    }
    .newArival-container .seeAll {
        margin-top: 30px;
    }
    .btn-primary {
        padding: 4px 10px !important;
    }
    .AddsBanner-container .button-container {
        width: 90%;
    }
    .popular-section {
        padding: 44px 0px;
    }
    .popular-section .popular-header {
        padding: 2px 26px 26px 26px;
    }
    .Avail .col-md-7 {
        padding: 0px 0px;
    }
    .Avail .detail-card {
        padding: 55px 26px;
    }
    .footer .inner-container {
        padding: 60px 18px;
    }
    .copyright {
        font-size: 12px;
    }
}

@media (max-width: 320px) {
    .AddsBanner-container .inner-container h4 {
        font-size: 9px;
    }
    .AddsBanner-container .inner-container {
        padding: 8px 97px 2px 15px;
    }
    .AddsBanner-container .button-container {
        width: 78%;
    }
    .popular-section .popular-header {
        padding: 2px 10px 22px 10px;
    }
    .NewsLetter-container p {
        font-size: 13px;
    }
    .copyright {
        font-size: 10px;
    }
    .btn-primary {
        padding: 2px 8px !important;
        font-size: 10px;
    }
}