
.Faqs .dark_black_div{
    background-color: #0D0D0D;
    color: white;
}
.Faqs .faq-answer {
    color: #7D7D7D;
    font-family: Roboto-Regular;
}
.Faqs .faq-question{
    color: #0D0D0D;
    font-size: 15px;
}
.Faqs .accordian_faqs{
    max-width: 650px;
    margin: 4rem auto;
}
.Faqs .red_div{
    background-color: #D60012;
    height: auto;
}
.Faqs .form_box{
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 2.5rem;
}
.Faqs .pos-rel{
    position: relative;
}
.Faqs .font-roboto{
    font-family: 'Roboto', sans-serif!important;
}
.Faqs .font-roboto-medium{
    font-family: Roboto-Medium;
}
.Faqs .font-sintony{
    font-family: 'Sintony', sans-serif;
}
.Faqs .bordr-top-lightgry{
    border-top: 1px solid lightgray;
}
.Faqs .border-0{
    border-radius: 0px!important;
}
.Faqs .f-32{
    font-size: 32px;
}
.Faqs .f-50{
    font-size: 50px;
}
.Faqs .f-14{
    font-size: 14px;
}
.Faqs .f-16{
    font-size: 16px;
}
.Faqs .inp-faq-page {
    font-size: 17px;
    border-radius: 0px;
    padding: 12px 20px;
    color: #222A41;
    font-family: Roboto-Regular;
    resize: none;
}
.Faqs .line-height-normal{
    line-height: normal;
}
.Faqs .mt-2-3{
    margin-top: 2.3rem;
}
.Faqs .mt-4rem{
    margin-top: 4rem!important;
}
.Faqs .text-right{
    text-align: right;
}
.Faqs .txt-area{
    resize: none;
}
.Faqs .f-15{
    font-size: 15px;
}
.Faqs .view_more{
    background-color: white;
    color: black;
    font-size: 15px;
    padding: 7px 13px;
    width: inherit;
    /* height: 34px; */
    border-radius: 0px;
}

.Faqs .border-white{
    width: auto;
    border: 1px solid white;
    float: right;
}
.Faqs .border-white:hover{
    color: white!important;
}
.Faqs .border-white:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
}
.Faqs .btn:hover {
    color: black;
}
.Faqs .row {
    margin-right: 0px!important;
    margin-left: 0px!important;
    --bs-gutter-x: 0rem!important;
}
.Faqs select.form-control { -webkit-appearance: menulist; }

@media (max-width: 1150px) {
    .Faqs .f-50 {
        font-size: 30px;
    }
    .p-res-faqs{
        padding: 1rem!important;
    }
}

/* media queries */
@media only screen and (max-width: 990px){
    .Faqs .media-mt-4{
        margin-top:1.5rem;
    }
    .Faqs .form_box {
        width: auto;
        top: -56%;
    }
}
