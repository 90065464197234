.home-blog-page{
    margin-top: 0px !important;
    margin-bottom: 88px;
}
.blog-page{
    margin-top: 88px;
    margin-bottom: 88px;
}
.blog-page .button-container {
    text-align: center;
}
.blog-page .header{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 20px;
}
.blog-page .card{
  background: transparent !important;
}
.blog-page .header p {
    font-size: 12px;
    font-family: Roboto-Regular;
    color: #ffff;
    text-transform: uppercase;
}
.blog-page .desc{
    font-size: 16px;
    font-family: Roboto-Regular;
    color: #ffff;
}

.blog-page .readMore {
    font-size: 13px;
    font-family: Roboto-Regular;
    font-weight: 500;
    color: #ffff;
}

.blog-page .arrow-icon {
    margin-left: 4px;
}
.blog-page .title{
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    font-family: Roboto-bold;
    margin-bottom: 44px;
}
.blog-page .title span{
    text-align: center;
    font-weight: bold;
    color: #EC0014;
    font-family: Roboto-bold;
}

/* blog Detail  */
.blog-detail {
    margin-top: 70px;
    margin-bottom: 70px;
}
.blog-detail .heading{
    font-size: 21px;
    font-family: Roboto-Bold;
    color: #ffff;
}
.blog-detail .desc{
    font-size: 15px;
    font-family: Roboto-Regular;
    color: #ffff;
}

.blog-detail .recentPost {
        width: 100%;
        padding: 16px;
        border: 1px solid #d60012;
}
.blog-detail .recentPost .header {
    padding: 16px 25px;
    background-color: #d60012;
    margin-bottom: 35px;
}
.blog-detail .recentPost .header .title {
    font-size: 18px;
    color: #ffff;
    font-family: Roboto-Regular;
    margin: 0;
}
.blog-detail .recentPost .postcard {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 15px;
}
.blog-detail .recentPost .desc {
        font-family: Roboto-Medium;
        font-size: 19px;
        color: #ffff;
        padding: 15px;
}

.blog-detail .recentPost .image-box {
    width: 140px;
    overflow: hidden;
}
.image-box img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}
.productDetail-image img {
    height: 100% !important;
}
/* blog Detail End  */

@media (max-width: 768px) {
    .blog-page {
        margin-top: 62px;
        margin-bottom: 62px;
    }
    .blog-page .desc {
        font-size: 11px;
    }
    .blog-page .header {
        margin-top: 12px;
    }
    .blog-detail .heading {
        font-size: 18px;
    }
    .blog-detail .desc {
        font-size: 12px;
    }
    .blog-detail .recentPost .desc {
        font-size: 12px;
        padding: 13px;
    }
}
@media (max-width: 425px) {
    .blog-detail .heading {
        font-size: 15px;
    }
    .blog-detail .recentPost .desc {
        font-size: 16px;
        padding: 25px;
    }
    
}
@media (max-width: 375px) {
    .blog-page {
        margin-top: 20px;
    }
}