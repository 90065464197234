@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Roboto:wght@300;500&display=swap');

.page_detaiils_breadcrum{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #1C1C1C;
    color : white;
}
.font-contact{
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: Bold;
}
.font-breadcrum{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}