.forgot_password .dark_black_div{
    background-color: #0D0D0D;
    color: white;
    min-height: 80vh;
}
.forgot_password .red_div{
    background-color: #D60012;
    height: 280px;
}
.forgot_password .mb-280{
    margin-bottom: 280px;
}
.forgot_password .forg-color{
    color: #B1B1B1;
}
.forgot_password .form_box{
    /* max-width: 500px ; */
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 2.5rem;
    position: relative;
    top: -69%;
}
.forgot_password .pos-rel{
    position: relative;
}
.forgot_password .d-flex{
    display: flex;
}
.forgot_password .by_creating_acc{
    display: flex;
    justify-content: center;
}
.forgot_password .font-roboto{
    font-family: 'Roboto', sans-serif!important;
}
.forgot_password .f-22{
    font-size: 22px;
}
.forgot_password .font-pop{
    font-family: 'Poppins', sans-serif !important;
}
.forgot_password .font-roboto-regular{
    font-family: Roboto-Regular
}
.forgot_password .font-roboto-medium{
    font-family:Roboto-Medium ;
}
.forgot_password .font-mon{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}
.forgot_password .form-signup{
    max-width: 450px;
    margin: auto;
    text-align: left;
}
.forgot_password .bg-red{
background: #D60012;
}
.forgot_password .signup-fld , .signup-fld:hover , .signup-fld:active , .signup-fld:focus{
    background: transparent;
    color: white;
    border-radius: 0px;
}
.forgot_password .brbd-no-rad{
    border-radius: 0px;
}
.forgot_password .f-31{
    font-size: 31px;
}
.forgot_password .f-14{
    font-size: 14px;
    font-weight: 400;
}
.forgot_password .f-21{
    font-size: 21px;
    font-weight: 400;
}
.forgot_password .clr-red{
    color:#D60012;
}
.forgot_password .mt-4rem{
    margin-top: 6rem!important;
}
.forgot_password .text-right{
    text-align: right;
}
.forgot_password .txt-area{
    resize: none;
}
.forgot_password .view_more{
    background-color: #D60012;
    color: white;
    padding: 7px 45px;
    border-radius: 0px;
}
.forgot_password .f-15{
    font-size: 15px;
    font-weight: 300;
}
.forgot_password .f-16{
    font-size: 16px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif!important;
}
.forgot_password .border-red{
    width: auto;
    height: 44px;
    border: 1px solid transparent;
    float: left;
}
.forgot_password .border-red:hover{
    border-color: white!important;
}
.forgot_password .border-red:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
}
.forgot_password .btn:hover {
    color: white;
}
.forgot_password .med-ps-5{
    padding-left:3rem ;
}
.forgot_password select.form-control { -webkit-appearance: menulist; }

/* == Otp == */
.forgot_passwordOTP .dark_black_div{
    background-color: #0D0D0D;
    color: white;
    min-height: 80vh;
}
.forgot_passwordOTP .red_div{
    background-color: #D60012;
    height: 280px;
}
.forgot_passwordOTP .mb-280{
    margin-bottom: 280px;
}
.forgot_passwordOTP .forg-color{
    color: #B1B1B1;
}
.forgot_passwordOTP .form_box{
    /* max-width: 500px ; */
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 2.5rem;
    position: relative;
    top: -69%;
}
.forgot_passwordOTP .pos-rel{
    position: relative;
}
.forgot_passwordOTP .d-flex{
    display: flex;
}
.forgot_passwordOTP .by_creating_acc{
    display: flex;
    justify-content: center;
}
.forgot_passwordOTP .font-roboto{
    font-family: 'Roboto', sans-serif!important;
}
.forgot_passwordOTP .font-roboto-bold{
    font-family: Roboto-Bold
}
.forgot_passwordOTP .font-roboto-regular{
    font-family: Roboto-Regular
}
.forgot_passwordOTP .font-roboto-medium{
    font-family:Roboto-Medium ;
}
.forgot_passwordOTP .f-22{
    font-size: 22px;
}
.forgot_passwordOTP .font-pop{
    font-family: 'Poppins', sans-serif !important;
}
.forgot_passwordOTP .font-mon{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}
.forgot_passwordOTP .form-signup{
    max-width: 450px;
    margin: auto;
    text-align: left;
}
.forgot_passwordOTP .bg-red{
    background: #D60012;
}
.forgot_passwordOTP .signup-fld , .signup-fld:hover , .signup-fld:active , .signup-fld:focus{
    background: transparent;
    color: white;
    border-radius: 0px;
}
.forgot_passwordOTP .brbd-no-rad{
    border-radius: 0px;
}
.forgot_passwordOTP .f-31{
    font-size: 31px;
}
.forgot_passwordOTP .f-14{
    font-size: 14px;
    font-weight: 400;
}
.forgot_passwordOTP .f-21{
    font-size: 21px;
    font-weight: 400;
}
.forgot_passwordOTP .clr-red{
    color:#D60012;
}
.forgot_passwordOTP .mt-4rem{
    margin-top: 6rem!important;
}
.forgot_passwordOTP .text-right{
    text-align: right;
}
.forgot_passwordOTP .txt-area{
    resize: none;
}
.forgot_passwordOTP .view_more{
    background-color: #D60012;
    color: white;
    padding: 7px 45px;
    border-radius: 0px;
}
.forgot_passwordOTP .f-15{
    font-size: 15px;
}
.forgot_passwordOTP .f-16{
    font-size: 16px;
}
.forgot_passwordOTP .border-red{
    width: auto;
    height: 44px;
    border: 1px solid transparent;
    float: left;
}
.forgot_passwordOTP .border-red:hover{
    border-color: white!important;
}
.forgot_passwordOTP .border-red:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
}
.forgot_passwordOTP .btn:hover {
    color: white;
}
.forgot_passwordOTP .med-ps-5{
    padding-left:3rem ;
}
.forgot_passwordOTP .f-18{
    font-size: 18px;
}
.forgot_passwordOTP .row{
    margin-right: 0px!important;
    margin-left: 0px!important;
    --bs-gutter-x: 0rem !important;
}
.forgot_passwordOTP select.form-control { -webkit-appearance: menulist; }

@media only screen and (max-width: 787px){
    .forgot_passwordOTP .mt-4rem{
        margin-top: 3rem!important;
    }
    .forgot_passwordOTP .f-31{
        font-size: 26px;
    }
}

/* == Otp End == */


/* new Password  */


.ForgotPasswordConfirm .row{
    margin-right: 0px!important;
    margin-left: 0px!important;
    --bs-gutter-x: 0rem !important;
}
.ForgotPasswordConfirm .dark_black_div{
    background-color: #0D0D0D;
    color: white;
    min-height: 80vh;
}
.ForgotPasswordConfirm .red_div{
    background-color: #D60012;
    height: 280px;
}
.ForgotPasswordConfirm .mb-280{
    margin-bottom: 280px;
}
.ForgotPasswordConfirm .forg-color{
    color: #B1B1B1;
}
.ForgotPasswordConfirm .form_box{
    /* max-width: 500px ; */
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 2.5rem;
    position: relative;
    top: -69%;
}
.ForgotPasswordConfirm .pos-rel{
    position: relative;
}
.ForgotPasswordConfirm .d-flex{
    display: flex;
}
.ForgotPasswordConfirm .by_creating_acc{
    display: flex;
    justify-content: center;
}
.ForgotPasswordConfirm .font-roboto{
    font-family: 'Roboto', sans-serif!important;
}
.ForgotPasswordConfirm .f-22{
    font-size: 22px;
}
.ForgotPasswordConfirm .font-pop{
    font-family: 'Poppins', sans-serif !important;
}
.ForgotPasswordConfirm .font-mon{
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
}
.ForgotPasswordConfirm .form-signup{
    max-width: 450px;
    margin: auto;
    text-align: left;
}
.ForgotPasswordConfirm .bg-red{
    background: #D60012;
}
.ForgotPasswordConfirm .signup-fld , .signup-fld:hover , .signup-fld:active , .signup-fld:focus{
    background: transparent;
    color: white;
    border-radius: 0px;
}
.ForgotPasswordConfirm .brbd-no-rad{
    border-radius: 0px;
}
.ForgotPasswordConfirm .f-31{
    font-size: 31px;
}
.ForgotPasswordConfirm .f-14{
    font-size: 14px;
    font-weight: 400;
}
.ForgotPasswordConfirm .f-21{
    font-size: 21px;
    font-weight: 400;
}
.ForgotPasswordConfirm .clr-red{
    color:#D60012;
}
.ForgotPasswordConfirm .mt-4rem{
    margin-top: 6rem!important;
}
.ForgotPasswordConfirm .text-right{
    text-align: right;
}
.ForgotPasswordConfirm .txt-area{
    resize: none;
}
.ForgotPasswordConfirm .view_more{
    background-color: #D60012;
    color: white;
    padding: 7px 45px;
    border-radius: 0px;
}
.ForgotPasswordConfirm .f-15{
    font-size: 15px;
    font-weight: 300;
}
.ForgotPasswordConfirm .f-16{
    font-size: 16px;
    font-weight: 300;
    font-family: 'Roboto', sans-serif!important;
}
.ForgotPasswordConfirm .border-red{
    width: auto;
    height: 44px;
    border: 1px solid transparent;
    float: left;
}
.ForgotPasswordConfirm .border-red:hover{
    border-color: white!important;
}
.ForgotPasswordConfirm .border-red:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
}
.ForgotPasswordConfirm .btn:hover {
    color: white;
}
.ForgotPasswordConfirm .med-ps-5{
    padding-left:3rem ;
}
.font-roboto-bold{
    font-family: Roboto-Bold;
}
.ForgotPasswordConfirm select.form-control { -webkit-appearance: menulist; }

@media only screen and (max-width: 787px){
    .ForgotPasswordConfirm .mt-4rem{
        margin-top: 3rem!important;
    }
    .ForgotPasswordConfirm .f-31{
        font-size: 26px;
    }
}
/* new password end  */


/* media queries */
@media only screen and (max-width: 990px){
    .forgot_password .media-mt-4{
        margin-top:1.5rem;
    }
    .forgot_password .form_box {
        width: auto;
        top: -56%;
    }
    .forgot_password .red_div {
        height: 350px;
    }
    .forgot_password .med-ps-5{
        padding-left:1rem ;
    }

    .forgot_passwordOTP .media-mt-4{
        margin-top:1.5rem;
    }
    .forgot_passwordOTP .form_box {
        width: auto;
        top: -56%;
    }
    .forgot_passwordOTP .red_div {
        height: 350px;
    }
    .forgot_passwordOTP .med-ps-5{
        padding-left:1rem ;
    }

    .ForgotPasswordConfirm .media-mt-4{
        margin-top:1.5rem;
    }
    .ForgotPasswordConfirm .form_box {
        width: auto;
        top: -56%;
    }
    .ForgotPasswordConfirm .red_div {
        height: 350px;
    }
    .ForgotPasswordConfirm .med-ps-5{
        padding-left:1rem ;
    }
}




