/* Product Card  */
.product-card {
    margin: 60px 0px 24px 0px;
    /* margin: 68px 0px; */
}
.product-card .card{
    background-color: unset !important;
    width: 100%;
    /* height: 220px; */
}
.product-card .card .card-image{
  /* height: 310px; */
  overflow: hidden;
}

.product-card .card a.card-image img {
    height: 400px;
    object-fit: cover;
    width: 100%;
}

.product-card .card .content-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px 0px 0px;
}

.product-card .card .title{
    font-size: 18px;
    color: #FFFFFF;
    font-family: Poppins-SemiBold;
}

.product-card .card .price {
    font-size: 16px;
    color: #FFFFFF;
    font-family: Poppins-SemiBold;
}

.product-card .card .desc {
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #707070;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
}
/* Hover  */
.product-card  .pop-cardHover {
    position: absolute;
    top: 0;
    bottom: 36px;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 0.6s;
}
.product-card .pop-cardHover:before {
    content: '';
    background: transparent;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.6s;
    z-index: 1 !important;
    /* z-index: 0; */
}

.product-card .pop-cardHover:hover:before {
    left: 12px;
    top: 12px;
}

.product-card .pop-cardHover:hover {
    background-color: #d6001280;
    position: absolute;
    top: -1px;
    bottom: 123px;
    right: 0px;
    /* font-size: 0; */
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100;
    color: #fff;
    
}
.inner-hoverDiv {
    z-index: 9 !important;
    cursor: pointer !important;
}
.product-card .icon-hover{
    padding: 14px;
    background-color: #ffff;
    color: #d60012;
    margin: 4px;
}
.product-card .card-inner {
    z-index: 9;
}
/* Product Card End  */

/* Categories  */
.category-margin{
    margin-top: 78px;
    margin-bottom: 78px;
}
.categories-container{
    width: 100%;
    padding: 16px;
    /* height: 200px; */
    border: 1px solid #d60012;
}
.categories-container .header {
    padding: 16px 25px;
    background-color: #d60012;
    margin-bottom: 25px;
}
.categories-container .title{
    font-size: 16px;
    color: #ffff;
    font-family: Bauhaus Regular;
    margin: 0;
}
.categories-container .cat-title {
    margin: 0px;
}
/* Categories end  */

.header-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.header-result select {
        color: #fff !important;
        background-color: transparent !important;
        border-color: #d60012 !important;
        padding: 6px 14px;
}
.header-result select option{
    color: rgb(7, 7, 7) !important;
  
}
.header-result select :hover {
        color: rgb(0, 0, 0) !important;
        background-color: transparent !important;
        border-color: #d60012 !important;
}

.result-text {
    color: #ffff;
    font-size: 15px;
    font-family: Poppins-Regular;
}

/* Price  */
.price-container{
    width: 100%;
    padding: 16px;
    /* height: 200px; */
    border: 1px solid #d60012;
}

.price-container .header {
    padding: 16px 25px;
    background-color: #d60012;
    margin-bottom: 25px;
}
.price-container .title{
    font-size: 16px;
    color: #ffff;
    font-family: Bauhaus Regular;
    margin: 0;
}
.price-container .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.price-container .input-container input{
    height: 40px;
    width: 100px;
    padding: 4px 8px;
}
.price-container .Pricefilter-btn {
    background-color: #d60012 !important;
    color: #FFFFFF !important;
    border: #d60012 !important;
    border-radius: 0px;
    margin-top: 14px;
    padding: 8px 34px;
}
.price-container .text{
    color: #FFFFFF;
    font-size: 16px;
    font-family: Bauhaus Regular;
    margin-top: 16px;
}
/* Price end  */

.searchBox-product input{
    padding: 6px 22px;
    border-radius: 8px;
    background-color: #0000;
    color: #fff;
    border: 1px solid#ffff;
}

/* Begin of Other Css */
.searchBox-product {
    position: relative;
}
.search-icon {
    position: absolute;
    right: 10px;
    top: 7px;
}
.search-icon i {
    color: #757575;
}
/* ============= */

@media (max-width: 1150px) {
    .product-card .card .title {
        font-size: 15px;
    }
    .product-card .card .price {
        font-size: 12px;
    }
    .price-container .input-container input {
        width: 82px;
    }
    .categories-container .header {
        padding: 12px 25px;
    }
    .price-container .header {
        padding: 12px 25px;
    }
    .product-card .pop-cardHover:hover {
        background-color: #d600124a;
        position: absolute;
        top: -1px;
        bottom: 120px;
        right: 0px;
        /* font-size: 0; */
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 100;
        color: #fff;
    }
}


@media (max-width: 1024px) {
    .product-card .pop-cardHover:hover {
        background-color: #d600124a;
        position: absolute;
        top: -1px;
        bottom: 120px;
        right: 0px;
        /* font-size: 0; */
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 100;
        color: #fff;
    }
}


@media (max-width: 768px) {
    .categories-container {
        padding: 8px;
    }
    .price-container {
        padding: 8px;
    }
    .price-container .input-container input {
        width: 62px;
    }
    .price-container .title {
        font-size: 12px;
    }
    .product-card .card .desc {
        font-size: 10px; 
    }
    .product-card .pop-cardHover:hover {
        top: -1px;
        bottom: 152px;
        right: 0px;
        left: 0px;
        opacity: 100;

    }
    .categories-container .cat-title {
font-size: 11px;
    }
  
}

@media (max-width: 425px) {
    .price-container .input-container input {
        width: 96%;
    }
    .price-container .Pricefilter-btn {
        padding: 10px 35px !important;
        font-size: 12px;
    }

    .header-result {
        margin-top: 24px;
    }
    .product-card .card .desc {
        font-size: 14px;
    }
    .product-card .card {
        margin-bottom: 30px;
    }
    .product-card .pop-cardHover:hover {
        top: -1px;
        bottom: 126px;
        right: 0px;
        left: 0px;
        opacity: 100;
    }
}
@media (max-width: 375px) {
    .product-card .card {
        margin-bottom: 26px;
    }
    .result-text {
        font-size: 12px;
    }
}

@media (max-width: 320px) {
    .product-card .pop-cardHover:hover {
        top: -1px;
        bottom: 148px;
        right: 0px;
        left: 0px;
        opacity: 100;
    }
}