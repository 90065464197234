.ShippingInfo .dark_black_div{
    background: #0D0D0D;
    }
    .f-32{
        font-size: 32px;
    }
.ShippingInfo .font-roboto-bold{
    font-family: Roboto-Bold;
}
.ShippingInfo .font-roboto-reg{
    font-family: Roboto-Regular;
}
.ShippingInfo .font-roboto-bold{
    font-family: Roboto-Bold;
}
.ShippingInfo .font-pop-reg{
    font-family: Poppins-Regular;
}
.ShippingInfo .font-roboto{
        font-family: 'Roboto', sans-serif!important;
    }
.ShippingInfo .img-one{
        width: 100%;
        height: auto;
    }
.ShippingInfo .pt-4rem{
        padding-top: 4rem;
    }
.ShippingInfo .f-16{
        font-size: 16px;
    }
.ShippingInfo .text-align-last-center{
        text-align-last: center ;
    }
.ShippingInfo .img-two{
        width: 100%;
        height: auto;
    }
.ShippingInfo .f-34{
        font-size: 24px;
    }
.ShippingInfo .f-27{
        font-size: 27px;
    }
.ShippingInfo .font-pop{
        font-family: 'Poppins', sans-serif !important;
    }
.ShippingInfo .table-color{
        color:  #BFBFBF;
    }
.ShippingInfo .table tr th {
        border-bottom: 2px solid transparent!important;
        border-top: 2px solid transparent!important;
    }
    
    @media only screen and (max-width: 700px){
        .ShippingInfo .f-34{
            font-size: 22px;
        }
        .ShippingInfo .f-27{
            font-size: 12px;
        }
        .ShippingInfo .f-32{
            font-size: 22px;
        }
        .ShippingInfo .f-16{
            font-size: 12px;     
        }
        .ShippingInfo .f-25{
            font-size: 11px;
        }
    }