
figure {
    padding: 0;
}
/* Table Css Start Here */
.bookmark{
    margin-top: 55px;
    margin-bottom: 85px;
}
.bookmark .title{
    color: #ffff;
    font-size: 31px;
    font-family: Roboto-Bold;
}
.bookmark .desc{
    color: #ffff;
    font-size: 16px;
    font-family: 'Roboto-Regular';
    text-align: center;
    margin-top: 20px;
}
.bookmark .tableDv .button-group a.btn.grey {
    background: #EEEEEE;
    color: #000;
}
.bookmark .heading {
    padding-bottom: 40px;
}
.bookmark table {
    width: 100%;
}
.bookmark .tableDv {
    background: #fff;
    /* margin: 0 70px; */
    padding: 35px 30px;
    border-radius: 0px;
}
.bookmark .products .productDetail .imgBox {
    overflow: hidden;
    width: 65px;
    height: 65px;    
    border-radius: 50%;
    /* margin: 16px; */
}
.bookmark .products .productDetail .product-detail {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.bookmark .products .productDetail .product-detail .img-box {
    min-width: 140px;
}
.bookmark table tr.topbar {
    border-bottom: 1px solid #C5C5C5;
    height: 40px;
}
.bookmark .products {
    height: 130px;
    border-bottom: 1px solid #C5C5C5;
}
.bookmark .tableDv th.productDetail {
    width: 50%;
}
.bookmark .tableDv .Proprice {
    width: 20%;
}
.bookmark .tableDv .addcart {
    width: 30%;
}
.bookmark .tableDv .addcart .ActionBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bookmark table tr.topbar .productDetail {
    text-align: center;
}
.bookmark .tableDv .addcart .ActionBtn .cartBtn p {
    margin-bottom: 0;
    font-size: 17px;
}
.bookmark .tableDv .addcart .ActionBtn .cartBtn p a {
    color: #e7adc4;
}
.bookmark .tableDv .addcart .ActionBtn .cartBtn p i {
    font-size: 15px;
}
.bookmark .tableDv .addcart .ActionBtn .deleteBtn i {
    color: #e7adc4;
    cursor: pointer;
}
.bookmark .tableDv .addcart .ActionBtn .deleteBtn i:hover {
    color: #57102c;
    cursor: pointer;
}
.bookmark .tableDv .button-group {
    margin-top: 30px;
}
.bookmark .tableDv .button-group a.btn {
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
    margin-right: 16px;
}
.bookmark .tableDv .button-group a.btn:nth-child(1):hover{
    background: #262262 !important;
}
.bookmark .tableDv .button-group a.btn.grey {
    background: #EEEEEE;
    color: #000;
}
.bookmark .button-group a.btn {
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
    margin-right: 16px;
}
.bookmark .button-group a.btn.grey {
    background: #EEEEEE;
    color: #000;
    padding: 10px 60px;
}

.bookmark .button-group{
    margin-top: 20px;
}
.bookmark .addToCart-btn{
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
}
.bookmark .topbar{
    font-size: 16px;
    font-family: Poppins-Bold;
    color: #4A4A4A;
}
.bookmark .ProductName {
        font-size: 13px;
        font-family: Poppins-SemiBold;
        color: #1F2C35;
        width: 80px;
}
.bookmark .total {
    font-size: 15px;
    font-family: Poppins-SemiBold;
    color: #1F2C35;
}
.bookmark .deleteBtn i {
    color: #EF5D61;
}

.bookmark .myOrder-delete {
    width: 8%;
}

.bookmark .ViewDetails{
    background: #000000;
    box-shadow: none;
    padding: 10px 20px;
    font-size: 11px;
    font-weight: 500;
    color: #fff;
    border-radius: 0px;
}

/* order view  modal  */
.orderView-Modal{
    padding: 20px 0px;
}

.orderView-Modal .modal-close{
    position: absolute;
    right: 0px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 18px;
}
.orderView-Modal .modal-close :hover{
    color: red;
}

.orderView-Modal tbody, td, tfoot, th, thead, tr {
    /* padding: 8px 0px !important; */
}

.orderView-Modal thead{
    border-bottom: 1px solid #363636;
}
.orderView-Modal .modal-productImg {
    height: 80px;
    width: 80px;
    overflow: hidden;
}
.orderView-Modal .modal-content .inner-content{
    padding: 40px 40px;
}
.orderView-Modal table {
    width: 100%;
}
/* order view modal end  */

/* Table Css End Here */
@media (max-width: 991px) {
    .bookmark .tableDv {
        margin: 0 0px;
        padding: 35px 10px;
    }
    .bookmark .products .productDetail .product-detail .img-box {
        min-width: 90px;
    }
}
@media(max-width: 575px) {
    .bookmark .tableDv th.productDetail {
        width: 25%;
    }
    .bookmark .products .productDetail .product-detail .name {
        display: none;
    }
    .bookmark table tr.topbar .productDetail {
        text-align: left;
    }
    .bookmark table tr.topbar th {
        font-size: 11px;
        text-align: center;
    }
    .bookmark .tableDv .addcart .ActionBtn .cartBtn p a {
        font-size: 0;
    }
    .bookmark .tableDv .addcart .ActionBtn {
        justify-content: space-evenly;
    }
    .bookmark .tableDv .button-group a.btn {
        width: 100%;
        margin: 5px 0;
    }
}

@media(max-width: 425px) {
    .bookmark .button-group a.btn {
        font-size: 12px;
    }
    
}
@media(max-width: 375px) {
    .bookmark .button-group a.btn {
        font-size: 10px;
    }
}

@media(max-width: 320px) {
    .bookmark .ViewDetails {
        padding: 8px 4px;
        font-size: 9px;
    }
   
    .bookmark table tr.topbar th {
        font-size: 10px;
    }
    .bookmark .button-group a.btn {
        margin-bottom: 12px;
    }
    .bookmark .products .productDetail .product-detail {
        display: block;
    }
    .bookmark .products .productDetail .imgBox {
        overflow: hidden;
        margin: 2px;
        height: 30px;
        width: 30px;
    }
    .bookmark .ProductName {
        font-size: 8px;
        margin-top: 10px;
    }
    .bookmark table tr.topbar .productDetail {
        white-space: nowrap;
        font-size: 7px;
    }
    .bookmark .tableDv .Proprice {
        white-space: nowrap;
        font-size: 7px;
    }
    .bookmark .addToCart-btn {
        padding: 5px 8px;
        font-size: 9px;
    }
    .bookmark .tableDv .addcart {
        width: 10%;
        font-size: 7px;
    }
}


