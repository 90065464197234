.POL .mt-4rem{
    margin-top:4rem;
    margin-bottom: 4rem;
}
.POL .dark_black_div{
    background-color: #0D0D0D;
    color: white;
}

.POL .font-roboto{
    font-family: Roboto-Regular;
}

.POL .f-16{
    font-size: 16px;
}

.POL .pt-5rem{
    padding-top: 5rem!important;
}

.POL select.form-control { -webkit-appearance: menulist; }

/* media queries */
@media only screen and (max-width: 990px){
    .POL .media-mt-4{
        margin-top:1.5rem;
        margin-bottom: 1.5rem;
    }
}


