.product-details {
    margin-top: 72px;
    margin-bottom: 72px;
}
.product-details .productName {
    font-size: 21px;
    font-family: Poppins-SemiBold;
    color: #FFFFFF;
}

.product-details .productDesc {
    font-size: 16px;
    font-family: Poppins-Regular;
    color: #FFFFFF;
}

.product-details .price-heart{
    display: flex;
    justify-content: space-between;
}

.product-details .price {
    font-size: 18px;
    font-family: Poppins-SemiBold;
    color: #FFFFFF;
    margin: 8px 8px 8px 0px;
}
.product-details .price s{
    font-size: 18px;
    font-family: Poppins-SemiBold;
    color: #d60012;
    /* margin: 8px; */
}
.product-details .AddBtn {
    padding: 10px 21px;
    background: #D60012;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-radius: 0px;
    border: unset;
    transition: 0.6s;
}

.product-details .AddBtn:hover {
    background: #fff;
    color: #000;
    border: unset;
}

.product-details .sizeBtn {
    padding: 10px 28px;
    background: transparent;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border: unset;
    transition: 0.6s;
    border: 1px solid #ffff;
    margin-left: 38px;
}
.product-details .sizeBtn:hover {
   
    background: #fff;
    color: #000;
    border: 1px solid rgb(10, 10, 10);
}

.product-details .list {
    display: flex;
    justify-content: initial;
    align-items: center;
    margin-top: 16px;
}

.product-details .listItems{
    font-size: 18px;
    font-family: Roboto-Regular;
    color: #fff;
    margin: 0px;
    /* margin-left: 6px; */
}

.product-details .qty-contaner{
    display: flex;
    margin-left: 24px;
}

.product-details .qty-btn{
    background-color: transparent;
    color: #ffff;
    border: 1px solid #ffff;
    padding: 8px 10px;
    transition: 0.6s;
    border-radius: 0px;
}
.product-details .qty-btn:hover{
    background-color: #fff;
    color: rgb(0, 0, 0);
    border: 1px solid #ffff;
    padding: 8px 10px;
    transition: 0.6s;
}
.product-details .qty-num{
        background-color: transparent;
        color: #ffff;
        border: 1px solid #ffff;
        padding: 8px 15px;
        margin: 0px 4px;
}
.product-details .quantity-wrap {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.product-details .quantity-wrap .text {
    font-size: 17px;
    font-family: 'Roboto-Medium';
    color: #fff;
}

.product-details .sizeSelect {
    width: 136px;
    background: transparent;
    color: #ffff;
    padding: 7px 15px;
    margin-left: 24px;
}
.product-details .sizeSelect option{
    /* width: 136px; */
    color: rgb(0, 0, 0);
    padding: 7px 15px;
}

/* product-slide  */
.product-slide .inner-image-container{
    padding: 20px 20px;
}

/* product-slide  end */

/* bottom-container  */
.bottom-container .nav-tabs {
    border-bottom: unset !important;
} 

.bottom-container .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #D60012;
    border-color: unset !important;
}
.bottom-container .nav-tabs .nav-link {
    border: 1px solid #edededf2 !important;
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
    color: #fff !important;
    margin-left: 14px !important;
}

.bottom-container  .detail-tab {
    padding: 50px 20px;
}
.bottom-container .detail-tab .title {
    font-size: 20px;
    color: #FFFFFF;
    font-family: Poppins-SemiBold;
}

.bottom-container .detail-tab .desc {
    font-size: 12px;
    font-family: Poppins-Regular;
    color: #FFFFFF;
}
.bottom-container .detail-tab ul li {
    color: #FFFFFF;
    font-size: 14px;
    font-family: Montserrat-Medium;
}

.bottom-container .review-container .title {
    font-size: 20px;
    color: #FFFFFF;
    font-family: Poppins-SemiBold;
}

/* bottom-container end  */

@media (max-width: 768px) {
    .product-details .productDesc {
        font-size: 12px;
    }
    .product-details .quantity-wrap .text {
        font-size: 13px;
    }
    .product-details .sizeSelect {
        width: 112px;
    }
    .product-slide .inner-image-container {
        padding: 7px 7px;
    }
    .product-details .listItems {
        font-size: 14px;
    }
    .product-details .AddBtn {
        font-size: 12px;
    }
    .product-details .sizeBtn {
        font-size: 12px;
    }
    .product-details .sizeSelect {
        margin-left: 8px;
    }
    .product-details .qty-contaner {
        margin-left: 8px;
    }
}

@media (max-width: 425px) {
    .product-details .quantity-wrap {
        margin-top: 16px;
    }
}
@media (max-width: 375px) {
    .product-details .price {
        font-size: 15px;
    }
}

@media (max-width: 320px) {
    .bottom-container .nav-tabs .nav-link {
        margin-left: 0px !important;
        font-size: 13px !important;
    }
    .bottom-container .detail-tab {
        padding: 38px 8px;
    }
    .bottom-container .detail-tab .desc {
        font-size: 11px;
    }
    .bottom-container .detail-tab ul li {
        font-size: 12px;
    }
}

