.signup .dark_black_div{
    background-color: #0D0D0D;
    color: white;
}
.signup .red_div{
    background-color: #D60012;
    height: 280px;
}
.signup .mb-280{
    margin-bottom: 280px;
}
.signup .form_box{
    /* max-width: 500px ; */
    background-color: white;
    width: 600px;
    margin: auto;
    padding: 2.5rem;
    position: relative;
    top: -69%; 
}
.signup .pos-rel{
    position: relative;
}
.signup .d-flex{
    display: flex;
}
.signup .by_creating_acc{
    display: flex;
    justify-content: center;
}
.signup .font-roboto{
    font-family: 'Roboto', sans-serif!important;
}
.signup .font-pop{
    font-family: 'Poppins', sans-serif !important;
}
.signup .font-mon{
    font-family: 'Montserrat', sans-serif;
}
.signup .form-signup{
    max-width: 500px;
    margin: auto;
    text-align: left;   
}
.signup .signup-fld , .signup .signup-fld:hover , .signup .signup-fld:active , .signup .signup-fld:focus{
    background: transparent;
    color: white;  
}
.signup .f-16{
    font-size: 16px;
}
.signup .f-17{
    font-size: 17px;
}
.signup .f-31{
    font-size: 31px;
}
.signup .f-14{
    font-size: 14px;
}
.signup .f-21{
    font-size: 21px;
    font-weight: 400;
}
.signup .f-15{
    font-size: 15px;
}
.signup .f-13{
    font-size: 13px;
}
.signup .clr-red{
    color:#D60012;
}
.signup .mt-4rem{
    margin-top: 4rem!important;
}
.signup .text-right{
    text-align: right;
}
.signup .txt-area{
    resize: none;
}
.signup .font-mon-meduim{
    font-family: Montserrat-Medium;
}
.signup .font-mon-semibold{
    font-family: Montserrat-SemiBold;
}
.signup .font-pop-reg{
    font-family: Poppins-Regular;
}
.signup .font-robot-reg{
    font-family: Roboto-Regular;
}
.signup .font-robot-medium{
    font-family: Roboto-Medium;
}
.signup .view_more{
    background-color: #D60012;
    color: white;
    padding: 7px 45px;
    border-radius: 0px;
}
.signup .border-red{
    width: auto;
    height: 44px;
    border: 1px solid transparent;
    float: left;
}
.signup .border-red:hover{
    border-color: white!important;
}
.signup .border-red:hover .view_more {
    position: relative;
    top: -4px;
    left: -4px;
}
.signup .btn:hover {
    color: white;
}
.signup select.form-control { -webkit-appearance: menulist; }

/* media queries */
@media only screen and (max-width: 990px){
    .signup .media-mt-4{
        margin-top:1.5rem;
    }
    .signup .form_box {
        width: auto;
        top: -56%;
    }
    .signup .red_div {
        height: 350px;
    }
    .signup .f-16{
        font-size: 14px;
    }
    .signup .f-17{
        font-size: 13px;
    }
    .signup .f-31{
        font-size: 23px;
    }
    .signup .f-14{
        font-size: 12px;
    }
    .signup .f-21{
        font-size: 17px;
    }
    .signup .f-15{
        font-size: 12px;
    }
    .signup .f-13{
        font-size: 19px;
    }
}

.form-signup input[type=number]::-webkit-inner-spin-button, 
.form-signup input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.confirm-password-icon {
    position: relative;
}
.icon-password {
    position: absolute;
    right: 20px;
    top: 54px;
}