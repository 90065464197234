.about .about-title{
    font-size: 47px;
    color: #FFFFFF;
    font-family: Roboto-Bold;
}
.about .about-title span{
    font-size: 47px;
    color: #D60012;
    font-family: Roboto-Bold;
}

.about .para{
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #FFFFFF;
}

.about .inner-container {
    padding: 68px 32px 68px 84px;
}

.about .right-imgbox {
    margin: 55px 0px;
}

/* new arrival section  */
.about-newarrival .about-title{
    font-size: 47px;
    color: #FFFFFF;
    font-family: Roboto-Bold;
}
.about-newarrival .about-title span{
    font-size: 47px;
    color: #D60012;
    font-family: Roboto-Bold;
}

.about-newarrival .para{
    font-family: Roboto-Regular;
    font-size: 16px;
    color: #FFFFFF;
}

.about-newarrival .inner-container {
        padding: 68px 40px 68px 5px;
}

.about-newarrival .right-imgbox1 {
    margin: 55px 0px;
}
.about-newarrival .right-imgbox2 {
    margin: 90px 0px;
}
/* new arrival section end  */

/* out team  */
.ourTeam{
    margin-top: 0px;
    margin-bottom: 88px;
}
.ourTeam .title{
    font-size: 47px;
    color: #FFFFFF;
    font-family: Roboto-Bold;
    text-align: center;
    margin-bottom: 50px;
}
.ourTeam .title span{
    font-size: 47px;
    color: #D60012;
    font-family: Roboto-Bold;
}
.ourTeam .team-detail {
    padding: 0px 35px;
}
.ourTeam .team-detail .inner-container{
    background-color: #D60012;
    color: #FFFFFF;
    text-align: center;
    padding: 14px;
    position: relative;
    bottom: 20px;
}

.ourTeam .team-detail .inner-container .title {
    font-size: 17px;
    font-family: Roboto-Bold;
    color: #ffffff;
    margin: 0px;
}

.ourTeam .team-detail .inner-container .sub-title {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #ffffff;
    margin: 0px;
    margin-top: 5px;
}
/* our team end  */

@media (max-width: 1150px) {
    .about .para {
        font-family: Roboto-Regular;
        font-size: 14px;
        color: #FFFFFF;
    }
    .about .inner-container {
        padding: 68px 28px 45px 34px;
    }
    .about-newarrival .para {
        font-size: 14px;

    }
}

@media (max-width: 768px) {
    .about .para {
        font-size: 12px;
    }
    .about-newarrival .para {
        font-size: 12px;
    }
    .ourTeam .team-detail {
        padding: 0px 10px;
    }
    .ourTeam .team-detail .inner-container .sub-title {
        font-size: 10px;
    }
    .ourTeam .team-detail .inner-container .title {
        font-size: 12px;
    }
}